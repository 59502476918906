import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import FaqComponent from "../../components/ui/Faq";
import TitleComponent from "../../components/ui/TitleComponent";

const FaqQuestions = [
    { id: 0,
      question: "Quais as vagas disponibilizadas para pessoas com deficiência?",
      answer:
        "As vagas são para os profissionais dos níveis fundamental, médio e superior. São disponibilizadas vagas para diversas funções, como auxiliar de higienização e limpeza, copeiro, recepcionista, técnico de enfermagem, enfermeiro, agente de portaria, maqueiro, fisioterapeuta, entre outras.",
    },
    { id: 1,
      question: "Quais as unidades com disponibilidade para contratação de pessoas com deficiência?",
      answer:
        "Existem vagas em todas as unidades que gerenciamos nos estados de Alagoas, Pará, Bahia, Tocantins, Paraná e Goiás, bem como na nossa sede administrativa no Distrito Federal.",
    },
    { id: 2,
      question: "Como faço para me inscrever?",
      answer:
        "Os interessados devem acessar nosso site, www.isac.org.br, buscar o edital exclusivo para deficientes e cadastrar os dados pessoais, currículo e laudo em PDF. Outra opção é o candidato se dirigir até uma de nossas unidades com o currículo atualizado e laudo.",
    },
    { id: 3,
      question: "Quais as etapas do processo seletivo?",
      answer:
        "Os candidatos interessados deverão fazer a inscrição no nosso, site www.isac.org.br, ou por meio de entrega de currículo presencial em nossas unidades. Os currículos são analisados e os pré-selecionados passam por entrevista comportamental e técnica. Após a aprovação na etapa da entrevista, os selecionados passam por avaliação da medicina do trabalho.",
    },
    { id: 4,
      question: "Qual a diferença entre as vagas de ampla concorrência e as disponibilizadas para pessoas com deficiência?",
      answer:
        "As vagas são semelhantes. O que existe é uma prioridade na contratação de Pessoas com Deficiência, tendo em vista a inclusão desses profissionais no mercado de trabalho. Valorizamos o conhecimento técnico, mas, principalmente, o engajamento profissional. Oportunizamos vagas com a intenção de promover saúde de qualidade e humanizada. As diferenças são respeitadas e prezamos por um ambiente saudável com integração entre pessoas e setores.",
    },
    { id: 5,
      question: "Quem pode se inscrever?",
      answer:
        "Pessoas maiores de 18 anos com qualquer tipo de deficiência, seja ela física, auditiva, visual, mental ou múltipla. No momento da admissão, o candidato deve apresentar os documentos obrigatórios solicitados pela equipe de Recursos Humanos.",
    },
    { id: 6,
      question: "Posso me candidatar à vaga mesmo sem experiência profissional?",
      answer:
        "Sim, somos uma instituição formadora. Há cargos para diversos níveis de escolaridade, sem a exigência de ter experiência na área. Temos programas de capacitação de colaboradores, plataforma de Educação à Distância, programa de acompanhamento mensal, bem como programas de avaliação de desempenho para o aprimoramento contínuo dos nossos colaboradores.",
    },
  ];

const PcdPage = () => {
  return (
    <React.Fragment>
      <Container>
        <Row className="mt-5">
          <Col md={8}>
            <TitleComponent title={"Dúvidas Frequentes - PCD"} />
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col md={12}>
            <p>
              O ISAC - Instituto Saúde e Cidadania informa que atua com a
              política de inclusão de PCD - Pessoas com Deficiência. Para isso,
              mantem em aberto, e com vigência contínua, um edital de todas as
              suas vagas para profissionais PCD.
            </p>
            <p>
              Os processos seletivos específicos para contratação de
              profissionais para as unidades gerenciadas pelo ISAC também
              destina vagas para PCD.
            </p>
            <p>
              O ISAC adota também a comunicação acessível como parte de suas
              boas práticas voltadas à inclusão.
            </p>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col md={12}>
              {FaqQuestions.map((item)=> (
            <FaqComponent 
            question={item.question}
            answer={item.answer}
            id={item.id}
            />
              ))}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PcdPage;
