import React from "react";
import CardResult from "../../ui/CardResult";
import { SingleProps } from "../../../types/single";
import TitleComponent from "../../ui/TitleComponent";
import ButtonComponent from "../../ui/ButtonComponent";
import { removeHtmlTags } from "../../../utils/functions";
import { ButtonArea } from "../../ui/ButtonComponent/styles";
import { Alert, Card, Col, Container, Row, Spinner } from "react-bootstrap";

const dataSistema: Date = new Date();

console.log(new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'short', year: 'numeric'} ).format(dataSistema))

const SinglePageTemplate: React.FC<SingleProps> = ({
  titulo,
  descricao,
  arquivos,
  edital,
  etapa,
  params,
  loading,
}) => {
  return (
    <Container>
      {loading ? (
        <Col md={12} className="text-center py-5">
          <Spinner animation="grow" />
        </Col>
      ) : (
        <React.Fragment>
          <Row className="py-5">
            <Col md={12}>
              <Card className="shadow-lg border-0">
                <Card.Body>
                  <TitleComponent title={`${titulo}`} />
                  <p dangerouslySetInnerHTML={{__html: (`${descricao}`)}}></p>
                  {params && (
                    <ButtonArea>
                      <ButtonComponent
                        title={
                          etapa?.status === "Finalizado"
                            ? "Encerrado"
                            : "Inscreva-se"
                        }
                        onClick={() => window.open("http://selecaogenesis.com.br/", "_blank")}
                        disabled={etapa?.status === "Finalizado" ? true : false}
                      />
                      <ButtonComponent
                        title="Ler edital"
                        onClick={() => window.open(edital?.url, "_blank")}
                      />
                    </ButtonArea>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
            <TitleComponent title={"Arquivos do Edital"} />
          <Row className="py-2">
            {arquivos?.length === 0 ? (
              <Alert className="m-auto" variant="light">
                Nenhum arquivo cadastrado.
              </Alert>
            ) : (
              arquivos?.map((item) => (
                <Col md={12} key={item?.id}>
                  <CardResult
                    titulo={item?.titulo}
                    descricao={item?.descricao}
                    dataInicial={item?.date_doc}
                    url={item?.documento?.url}
                  />
                </Col>
              ))
            )}
          </Row>
        </React.Fragment>
      )}
    </Container>
  );
};

export default SinglePageTemplate;
