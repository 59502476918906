import { Button } from './styles';
import { ButtonProps } from '../../../types/button';
const ButtonComponent: React.FC<ButtonProps> = ({ title, size, ...props }) => {
  return (
    <Button {...props} style={{ fontSize: `${size}rem` }}>
      {title}
    </Button>
  );
};

export default ButtonComponent;
