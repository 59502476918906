import styled from 'styled-components';

export const Item = styled.div`
  a {
    text-decoration: none;
    h3 {
      color: ${(props) => props.theme.color.isacBlue};
      position: relative;
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        left: -20px;
        top: 10px;

        border-top: 0.4rem solid transparent;
        border-bottom: 0.4rem solid transparent;
        border-left: 0.6rem solid ${(props) => props.theme.color.isacLightBlue};
      }
    }
    p {
      color: ${(props) => props.theme.color.isacGray};
      margin-bottom: 0;
    }
  }
  padding: 1rem 1rem;
`;
