import React from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { Process } from "../../../types/process";
import { States } from "../../../types/states";
import TitleComponent from "../../ui/TitleComponent";
import ListItem from "../../ui/ListItem";
import { removeHtmlTags } from "../../../utils/functions";

type CompletedTemplateProps = {
  title?: string;
  processes?: Process[];
  states?: States[];
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defValue?: string;
};

const CompletedPageTemplate: React.FC<CompletedTemplateProps> = ({
  title,
  processes,
  states,
  loading,
  onChange,
  defValue,
}) => {
  return (
    <Container>
      <Row className="py-5">
        <Col md={8}>
          <TitleComponent title={`Processos seletivos finalizados`} />
        </Col>
        <Col md={4}>
          <select onChange={onChange}>
            <option value={0}>Selecione o Estado</option>
            {states?.map((item: any) => (
              <option key={item?.id} value={item?.sigla}>
                {item?.estado}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        {defValue === "0" ? (
          <React.Fragment>
            <Col md={12} className="text-center py-3">
              <Alert variant="light">
                Selecione o Estado do processo seletivo.
              </Alert>
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {loading ? (
              <Col md={12} className="text-center py-3">
                <Spinner animation="grow" />
              </Col>
            ) : (
              <React.Fragment>
                {processes?.length === 0 ? (
                  <Col md={12} className="text-center py-3">
                    <Alert variant="light">Nenhum processo registrado.</Alert>
                  </Col>
                ) : (
                  processes?.map((item) => (
                    <Col md={12} key={item?.id}>
                      <ListItem
                        key={0}
                        title={item?.titulo}
                        description={removeHtmlTags(
                          `${removeHtmlTags(
                            item?.descricao?.substr(0, 250)
                          )}...`
                        )}
                        link={item?.slug}
                      />
                    </Col>
                  ))
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Row>
    </Container>
  );
};

export default CompletedPageTemplate;
