// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: 'Dafault',
    color: {
        isacBlue:'#13387c',
        isacLightBlue:'#2389c2',
        isacLightOpacityBlue:'rgba(19, 56, 124, .4)',
        isacGray:'#59595a',
        isacDarkGreen: '#138063',
        isacLigthGreen: '#0dbc8d',
        white: '#fff'
    }  
};