import HomePageTemplate from "./template";
import { Process } from "../../../types/process";
import { States } from "../../../types/states";
import {HomeTexts} from '../../../types/homeText';
import {Posts} from '../../../types/post';
import { FaqTypes } from "../../../types/faq";


type HomePageTemplateProps = {
  titleOpenedOpportunies?: string;
  titleClosedOpportunies?: string;
  defaultIMG?: string;
  dataOpened?: Process[];
  dataClosed?: Process[];
  banners?: [];
  states?: States[];
  posts?: Posts[];
  loading?: boolean;
  openedDefaultValue?: string;
  closedDefaultValue?: string;
  textHome?: HomeTexts[];
  duvidasFrequentes?: FaqTypes[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;

};

const HomePage: React.FC<HomePageTemplateProps> = ({
  titleOpenedOpportunies,
  titleClosedOpportunies,
  defaultIMG,
  dataOpened,
  dataClosed,
  banners,
  states,
  posts,
  loading,
  openedDefaultValue,
  closedDefaultValue,
  textHome,
  duvidasFrequentes,
  onChange,
  onKeyUp

}) => (
  <HomePageTemplate
    titleOpenedOpportunies={titleOpenedOpportunies}
    titleClosedOpportunies={titleClosedOpportunies}
    defaultIMG={`${defaultIMG}`}
    dataOpened={dataOpened}
    dataClosed={dataClosed}
    banners={banners}
    loading={loading}
    states={states}
    posts={posts}
    openedDefaultValue={openedDefaultValue}
    closedDefaultValue={closedDefaultValue}
    duvidasFrequentes={duvidasFrequentes}
    textHome={textHome}
    onChange={onChange}
    onKeyUp={onKeyUp}
  />
);

export default HomePage;
