import styled from 'styled-components';

export const Btn = styled.div`
  text-align: right;
  font-size: 1.2rem;
  margin-top: 2rem;
  a {
    border: 2px solid ${(props) => props.theme.color.isacLightBlue};
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    color: ${(props) => props.theme.color.isacLightBlue};
    transition: all 0.2s;
    &:hover {
      background: ${(props) => props.theme.color.isacBlue};
      border: 2px solid ${(props) => props.theme.color.isacBlue};
      color: ${(props) => props.theme.color.white};
    }
  }
`;

export const Divisor = styled.hr`
  height: 6px;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.color.isacLightBlue};
`;

export const CardContent = styled.div`
  margin-top: 5%;
  a {
    text-decoration: none;
  }
  .h5 {
    a {
      color: ${(props) => props.theme.color.isacBlue};
    }
  }
  .card-text {
    a {
      color: ${(props) => props.theme.color.isacGray};
    }
  }

  .card {
    border-radius: 1.5rem;
    img {
      border-radius: 1.5rem;
    }
  }
`;
