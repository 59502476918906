import { Process } from "../../../types/process";
import { States } from "../../../types/states";
import OngoingPageTemplate from "./template";

type OngoingTemplateProps = {
  title?: string;
  processes?: Process[];
  states?: States[];
  loading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  defValue?: string;
};

const OngoingPage: React.FC<OngoingTemplateProps> = ({
  title,
  processes,
  states,
  loading,
  onChange,
  defValue,
}) => (
  <OngoingPageTemplate
    title={title}
    processes={processes}
    states={states}
    loading={loading}
    onChange={onChange}
    defValue={defValue}
  />
);

export default OngoingPage;
