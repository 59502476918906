import React from "react";
import OngoingPage from "../../components/elements/OngoingProcess";
import api from "../../services/api";

const OngoingProcess = () => {
  const [title, setTitle] = React.useState("teste");
  const [states, setStates] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [defValue, setDefValue] = React.useState("0");
  const [processes, setProcesses] = React.useState();
  const [UF, setUF] = React.useState();

  React.useEffect(() => {
    api.get("estados").then((res) => {
      setStates(res.data);
    });
  }, []);

  React.useEffect(() => {
    api
      .get(
        `vagas?estado.sigla=${UF}&etapa.status=Andamento&_sort=dataInicial:DESC`
      )
      .then((res) => {
        setProcesses(res.data);
        setLoading(false)
      });
  }, [UF]);

  const handleChange = (e: any) => {
    const { value } = e.target;
    setUF(value);
    setDefValue(value)
  };

  console.log("Estados", processes);

  return (
    <OngoingPage
      processes={processes}
      states={states}
      loading={loading}
      onChange={handleChange}
      defValue={defValue}
    />
  );
};

export default OngoingProcess;
