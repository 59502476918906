import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: none;
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100%;
    } 

    *, button, input {
        border: 0;
        font-family:'Poppins', sans-serif;
    }
    select {
        border: 2px solid ${(props) => props.theme.color.isacLightBlue};
        background-color: ${(props) => props.theme.color.white};
        color: ${(props) => props.theme.color.isacGray};
        border-radius: 2rem;
        width: 100%;
        font-size: 1.2rem;
        height: 3rem;
        padding: 0 .8rem;
    }
    .card {
        border-radius: 1rem;
    }
`;
