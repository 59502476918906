import React from 'react';
import { SocialProps } from '../../../types/social';
import { Social, SocialItem } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialComponent: React.FC<SocialProps> = ({ data }) => {
  return (
    <Social>
      {data?.map((item) => (
        <SocialItem key={item.id}>
          <a href={`${item.url}`} target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={item.icon} color="#fff" />
          </a>
        </SocialItem>
      ))}
    </Social>
  );
};

export default SocialComponent;
