import styled from 'styled-components';

export const TopMenu = styled.div`
  .top-menu-area {
    z-index: 9;
    border-top: 6px solid ${(props) => props.theme.color.isacLightBlue};
    background: ${(props) => props.theme.color.isacBlue};
    border-bottom-left-radius: 2rem;
    ul {
      margin-bottom: 0;
      a {
        background: transparent;
      }
    }
  }
`;
