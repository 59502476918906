import styled from "styled-components";

export const CardResultItem = styled.div`
  margin-top:5%;
  background: ${(props) => props.theme.color.white};

  h4 {
    color: ${(props) => props.theme.color.isacBlue};
  }
  
  hr {
    height: 0.2rem;
    border-radius: 1rem;
    background: ${(props) => props.theme.color.isacLightBlue};
  }
`;
