import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ListItemProps } from "../../../types/listItem";
import ButtonComponent from "../ButtonComponent";
import { ButtonArea } from "../ButtonComponent/styles";
import { Item } from "./styles";

const ListItem: React.FC<ListItemProps> = ({
  title,
  link,
  description,
  status,
}) => {
  return (
    <Col md={12} className="mb-5">
      <Card className="shadow-lg border-0">
        <Card.Body>
          <Item>
            <Link to={`/processo/${link}`}>
              <h3>{title}</h3>
              <p dangerouslySetInnerHTML={{__html: (`${description?.substring(0,250)}...`)}}></p>
            </Link>
            {status && (
              <ButtonArea>
                <ButtonComponent title="Inscreva-se" disabled={status} />
                <ButtonComponent title="Ler edital" />
              </ButtonArea>
            )}
          </Item>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ListItem;
