import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/ui/Footer";
import Header from "./components/ui/Header";
import { Home, Single} from "./pages";
import CompletedProcess from "./pages/completedProcess";
import OngoingProcess from "./pages/ongoingProcess";
import PcdPage from "./pages/pcd";

const Routes = () => (
  <Router>
    <React.Fragment>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/processo/:slug" component={Single} />
        <Route
          exact
          path="/processos-seletivos-andamento"
          component={OngoingProcess}
        />
        <Route
          exact
          path="/processos-seletivos-encerrados"
          component={CompletedProcess}
        />
        <Route 
        exact path="/pcd"
        component={PcdPage}
        />
      </Switch>
      <Footer />
    </React.Fragment>
  </Router>
);

export default Routes;
