import styled from 'styled-components';

export const FooterSection = styled.div`
  box-shadow: 0 0.5rem 6rem rgba(0, 0, 0, 0.2) !important;
  border-radius: 50% 50% 0 0;
  a {
    color: ${(props) => props.theme.color.isacGray};
  }
  a:hover {
    color: ${(props)=> props.theme.color.isacLightBlue}
  }
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
