import React from "react";
import api from "../../services/api";
import { Container } from "react-bootstrap";
import HomePage from "../../components/elements/HomePage";
import defaultIMG from "../../assets/img/default-img.png";

const Home = () => {
  const [states, setStates] = React.useState();
  const [banners, setBanners] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [dataOpened, setDataOpened] = React.useState();
  const [openedDefaultValue, setOpenedDefaultValue] = React.useState("");
  const [closedDefaultValue, setClosedDefaultValue] = React.useState("");
  const [dataAll, setDataAll] = React.useState();
  const [valueUF, setValueUF] = React.useState("");
  const [valueStatus, setValueStatus] = React.useState("");
  const [textsHome, setTextsHome] = React.useState([]);
  const [posts, setPosts] = React.useState()
  const [duvidasFrequentes, setDuvidasFrequentes] = React.useState()

  
  React.useEffect(()=> {
    api.get("home-texts")
    .then((res)=>{
      setTextsHome(res.data)
    })
  },[])

  React.useEffect(()=>{
    api.get("duvidas-frequentes")
    .then((res)=> {
      setDuvidasFrequentes(res.data)
    })
  },[])
  

  React.useEffect(()=> {
    api.get("vagas?destaque=true")
    .then((res)=>{
      setPosts(res.data)
    })
  },[])
  
  React.useEffect(()=>{
    if(valueUF === "0" || valueStatus === "0") {
      api.get("vagas?etapa.status=Andamento")
      .then((res)=> {
        setDataAll(res.data)
        setLoading(false)
      })
    } else {
      api.get(`vagas?estado.sigla=${valueUF}&etapa.status=${valueStatus}&_sort=dataInicial:DESC`)
      .then((res)=>{
        setDataAll(res.data)
        setLoading(false)
      })
    }
    api.get("estados?_sort=sigla:asc").then((res) => {
      setStates(res.data);
    });
    api.get("banners").then((res) => {
      setBanners(res.data);
    });
  }, [valueStatus, valueUF])


  const handleChange = (e:any) => {
    const {id, value} = e.target;
    const typeId = id

    if((typeId === "Estado" || typeId === "Status") && 
    value === 0 ) {
      setClosedDefaultValue("0");
      setOpenedDefaultValue("0");
    } else {
      if (typeId === "Estado") {
        setClosedDefaultValue(value)
        setValueUF(value)
      }
      if (typeId === "Status") {
        setOpenedDefaultValue(value)
        setValueStatus(value)
      }
    }
  }

  const handleSearch = (e:any) => {
    console.log('evento de busca', e.target.value)
  }



  return (
    <React.Fragment>
      <Container className="px-0" fluid>
        <HomePage
          defaultIMG={defaultIMG}
          banners={banners}
          states={states}
          loading={loading}
          onChange={handleChange}
          onKeyUp={handleSearch}
          dataOpened={dataOpened}
          dataClosed={dataAll}
          closedDefaultValue={closedDefaultValue}
          openedDefaultValue={openedDefaultValue}
          titleOpenedOpportunies="Estado"
          titleClosedOpportunies="Status"
          textHome={textsHome}
          posts={posts}
          duvidasFrequentes={duvidasFrequentes}
        />
      </Container>
    </React.Fragment>
  );
};

export default Home;
