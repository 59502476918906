import moment from "moment";
import 'moment/locale/pt-br'
import { Card } from "react-bootstrap";
import { removeHtmlTags } from "../../../utils/functions";
import ButtonComponent from "../ButtonComponent";
import { ButtonArea } from "../ButtonComponent/styles";
import { CardResultItem } from "./styles";
moment.locale('pt-br');

type CardResultProps = {
  titulo?: string;
  descricao?: string;
  url?: string;
  dataInicial?: string;
  date_doc?:string;
};

const CardResult: React.FC<CardResultProps> = ({
  titulo,
  descricao,
  url,
  dataInicial,
  date_doc
}) => {
  return (
    <CardResultItem>
      <Card className="border-0 shadow-lg bg-transparent">
        <Card.Body className="bg-transparent">
          <h4 className="mb-0">{titulo}</h4>
          <hr />
          <p className="mb-0">{moment(dataInicial).format("DD [de] MMMM [de] YYYY")}</p>
          <p className="mb-0">{removeHtmlTags(descricao)}</p>
          <ButtonArea>
            <ButtonComponent
              title="Ver Comunicado"
              size={0.8}
              onClick={() => window.open(url, "_blank")}
            />
          </ButtonArea>
        </Card.Body>
      </Card>
    </CardResultItem>
  );
};

export default CardResult;
