import React from "react";
import { Image } from "react-bootstrap";

import { TitleH2 } from "./styles";
import divisorIMG from "../../../assets/img/divisor.png";

export type TitleComponentProps = {
  title: string;
};

const TitleComponent: React.FC<TitleComponentProps> = ({ title }) => (
  <React.Fragment>
    <TitleH2>{title}</TitleH2>
    <Image src={divisorIMG} />
  </React.Fragment>
);

export default TitleComponent;
