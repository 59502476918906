import React from "react";
import { Carousel, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CarouselTypes } from "../../../types/carousel";

const CarouselComponent: React.FC<CarouselTypes> = ({ data, defaultIMG }) => {
  return (
    <Carousel>
      {data?.length === 0 ? (
        <Image
          className="d-block w-100"
          src={defaultIMG}
          alt="Imagem padrão"
        />
      ) : (
        data?.map((item: any) => (
          <Carousel.Item key={item?.id}>
            <Link to={item?.url}>
              <Image
                className="d-block w-100"
                src={item.midia[0].formats.large.url}
                alt={item?.id}
              />
            </Link>
          </Carousel.Item>
        ))
      )}
    </Carousel>
  );
};

export default CarouselComponent;
