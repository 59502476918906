import styled from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  width: auto;
  font-size: 1.5rem;
  margin-top: 2rem;
  cursor: pointer;
  border: 2px solid ${(props) => props.theme.color.isacLightBlue};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme.color.isacLightBlue};
  transition: all 0.2s;
  background: transparent;
  &:hover {
    background: ${(props) => props.theme.color.isacBlue};
    border: 2px solid ${(props) => props.theme.color.isacBlue};
    color: ${(props) => props.theme.color.white};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background: transparent;
      color: ${(props) => props.theme.color.isacLightBlue};
      border: 2px solid ${(props) => props.theme.color.isacLightBlue};
    }
  }
`;

export const ButtonArea = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
