import React from 'react';
import { Nav, Navbar, Container, Image, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LogoImg from '../../../assets/img/logo.svg';
import SocialComponent from '../../ui/SocialComponent';
import { TopMenu } from './styles';
import {
  faInstagram,
  faFacebookF,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

const data = [
  { id: 1, url: 'https://www.facebook.com/isacsaude', icon: faFacebookF },
  { id: 2, url: 'https://www.instagram.com/isac_os/', icon: faInstagram },
  { id: 3, url: 'https://www.linkedin.com/company/isac-instituto-saude-e-cidadania/', icon: faLinkedin },
  { id: 4, url: 'https://www.youtube.com/channel/UCH7g5mRH3THHjNp-GEIk5og', icon: faYoutube }
];
const Header = () => (
  <React.Fragment>
    <TopMenu>
      <Container
        className="position-absolute top-header left-header d-none d-md-block"
        fluid
      >
        <Row>
          <Col
            md={7}
            className="offset-md-5 py-3 top-menu-area text-white d-flex align-items-center justify-content-between"
          >
            <Col
              md={8}
              className=" d-flex align-items-center justify-content-between"
            >
              <h5 className="mb-0">Portal de Vagas</h5>
              <SocialComponent data={data} />
            </Col>
          </Col>
        </Row>
      </Container>
    </TopMenu>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link className="nav-link" to="/">
            <Image src={LogoImg} width="200" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mt-md-5 pt-md-4">
            <Link className="nav-link" to="/">
              Início
            </Link>
            <Link className="nav-link" to="/processos-seletivos-andamento">
              Em Andamento
            </Link>
            <Link className="nav-link" to="/processos-seletivos-encerrados/">
              Finalizados
            </Link>
            <Link className="nav-link" to="/pcd">
              PCD
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </React.Fragment>
);

export default Header;
