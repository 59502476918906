import React from 'react';
import {
  faInstagram,
  faFacebookF,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { FooterSection } from './styles';
import LogoImg from '../../../assets/img/logo.svg';
import SocialComponent from '../SocialComponent';
import TitleComponent from '../TitleComponent';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';

const data = [
  { id: 1, url: 'https://www.facebook.com/isacsaude', icon: faFacebookF },
  { id: 2, url: 'https://www.instagram.com/isac_os/', icon: faInstagram },
  { id: 3, url: 'https://www.linkedin.com/company/isac-instituto-saude-e-cidadania/', icon: faLinkedin },
  { id: 4, url: 'https://www.youtube.com/channel/UCH7g5mRH3THHjNp-GEIk5og', icon: faYoutube }
];

const Footer: React.FC = () => {
  return (
    <FooterSection>
      <Container className="py-5">
        <Row className="pt-md-5">
          <Col md={4}>
            <Image src={LogoImg} width={200} className="py-4" />
            <p>
            O ISAC é uma organização social sem fins lucrativos que realiza a gestão de serviços de saúde e administração hospitalar para entes públicos e privados.
            </p>
            <SocialComponent data={data} />
          </Col>
          <Col md={4}>
            <TitleComponent title="Links" />
            <Nav className="flex-column">
              <Nav.Link href="/">Início</Nav.Link>
              <Nav.Link href="https://isac.org.br/politica-de-privacidade" target="_blank">Política de Privacidade</Nav.Link>
              <Nav.Link href="https://isac.org.br/canal-de-denuncias/" target="_blank">Canal de Denúncias</Nav.Link>
              <Nav.Link href="https://isac.org.br/quem-somos/" target="_blank">Quem Somos</Nav.Link>
              <Nav.Link href="https://transparencia.isac.org.br/" target="_blank">Portal de Transparência</Nav.Link>
              <Nav.Link href="https://isac.org.br/category/noticias/" target="_blank">Notícias do ISAC</Nav.Link>
            </Nav>
          </Col>
          <Col md={4}>
          <TitleComponent title="Contato" />
            <Nav className="flex-column">
              <Nav.Link href="mailto:sac@isac.org.br" target="_bank">sac@isac.org.br</Nav.Link>
              <Nav.Link href="tel:+556121967841" target="_bank">(61) 2196-7841</Nav.Link>
              <Nav.Link href="https://g.page/isacsaude?share" target="_blank">
              Setor Comercial Sul Quadra 09 Bloco C, Torre C, Ed. Parque
                  Cidade Corporate Sala 1001, Asa Sul
                  <br />
                  CEP: 70308-200, Brasília/DF
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </FooterSection>
  );
};

export default Footer;
