import React from "react";
import { Files } from "../../../types/process";
import { Edital, State } from "../../../types/single";
import SinglePageTemplate from "./template";

type SinglePageProps = {
  titulo: string;
  descricao?: string;
  params?: string;
  arquivos?: Files[];
  edital?: Edital;
  etapa?: State;
  loading?: boolean;
};

const SinglePage: React.FC<SinglePageProps> = ({
  titulo,
  descricao,
  arquivos,
  params,
  edital,
  etapa,
  loading
}) => {
  return (
    <SinglePageTemplate
      titulo={titulo}
      descricao={descricao}
      arquivos={arquivos}
      params={params}
      edital={edital}
      etapa={etapa}
      loading={loading}
    />
  );
};

export default SinglePage;
