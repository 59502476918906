import React from "react";
import { Card, Accordion } from "react-bootstrap";
import { FaqTypes } from "../../../types/faq";


const FaqComponent: React.FC<FaqTypes> = ({ question, answer, id, Answer }) => {
  return (
          <Accordion>
            <Accordion.Toggle as={Card.Header} eventKey={`${id}`}>
              {question}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${id}`}>
              <Card.Body>
                <div dangerouslySetInnerHTML={{__html: (`${answer}`)}}></div>
              </Card.Body>
            </Accordion.Collapse>
          </Accordion>
  );
};

export default FaqComponent;
