import styled from 'styled-components';

export const Social = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`;

export const SocialItem = styled.li`
  a {
    background: ${(props) => props.theme.color.isacLightBlue};
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s;
    color: ${(props) => props.theme.color.white};
    &:hover {
      background: ${(props) => props.theme.color.isacBlue};
    }
  }
`;
