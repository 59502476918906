import api from "./api";

class ProcessService {
  getStates() {
    return api.get("estados");
  }

  getAll() {
    return api.get("vagas");
  }

  getProcessTypeWithUf(status: string, uf: string) {
    return api.get(`vagas?etapa.status=${status}&estado.sigla=${uf}`);
  }

  getSingleProcess(slug: string) {
    return api.get(`vagas?slug=${slug}`);
  }
}

export default new ProcessService();
