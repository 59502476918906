import styled from 'styled-components';


export const Select = styled.select`
  color: ${(props) => props.theme.color.isacBlue};
  border: 2px solid ${(props) => props.theme.color.isacLightOpacityBlue};
  border-radius: 1rem;
  width: 100%;
  font-size: 1.2rem;
  height: 3rem;
  background: ${(props) => props.theme.color.white};
`;

export const Search = styled.input`
  border: 2px solid ${(props) => props.theme.color.isacLightBlue};
  background-color: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.isacGray};
  width: 100%;
  border-radius: 2rem;
  font-size: 1.2rem;
  height: 3rem;
  padding: 0 .8rem;

`;

export const Botao = styled.button`
   border: 2px solid ${(props) => props.theme.color.isacLightBlue};
   background-color: ${(props) => props.theme.color.white};
   color: ${(props) => props.theme.color.isacGray};
   width: 100%;
   padding: 0.8rem;
   border-radius: 2rem;
   font-size: 1.2rem;
   :hover{
    background-color:${(props) => props.theme.color.isacLightBlue};
    color: #FFF;
    transition: background-color 200ms linear;
   }
`;
