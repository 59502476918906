import React from "react";
import { States } from "../../../types/states";
import { Link } from 'react-router-dom';
import { Process } from "../../../types/process";
import {HomeTexts} from '../../../types/homeText';
import { Posts } from "../../../types/post";
import CardComponent from "../../ui/CardComponent";
import TitleComponent from "../../ui/TitleComponent";
import { removeHtmlTags } from "../../../utils/functions";
import CarouselComponent from "../../ui/CarouselComponent";
import FaqComponent from '../../ui/Faq';
import { Botao } from "./styles";
import { Alert, Col, Container, Row, Spinner, Button} from "react-bootstrap";

import { FaqTypes } from "../../../types/faq";

type HomePageTemplateProps = {
  banners?: [];
  loading?: boolean;
  states?: States[];
  posts?: Posts[];
  defaultIMG?: string;
  dataOpened?: Process[];
  dataClosed?: Process[];
  openedDefaultValue?: string;
  closedDefaultValue?: string;
  titleOpenedOpportunies?: string;
  titleClosedOpportunies?: string;
  duvidasFrequentes?: FaqTypes[];
  textHome?: HomeTexts[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onKeyUp?: (e:React.KeyboardEvent<HTMLInputElement>) => void;
};

const duvidasFrequentes = [
  {id: 0, question: "Quero trabalhar no ISAC, o que preciso fazer?", answer: "O ISAC é uma organização social que tem na sua cultura a integridade, transparência, legalidade e verdade. Pelo nosso Jeito ISAC de ser, cuidar e trabalhar, democratizamos o acesso a todas as nossas vagas. E hoje, todas as nossas contratações são realizadas por meio de processos seletivos que são publicados aqui no Portal de Vagas."},
  {id: 1, question: "O ISAC recebe currículos por e-mail, presencialmente, ou pelas redes sociais?", answer: "Todas as nossas contratações são realizadas por meio de processos seletivos que são publicados aqui no nosso portal de vagas. E por isso, os currículos enviados para nossos e-mails e/ou redes sociais são descartados."},
  {id: 2, question: "Como faço para saber quais processos seletivos estão em andamento nas unidades ISAC?", answer: "Todos os editais dos processos seletivos que estão em andamento em nossas unidades estão disponíveis em nosso site, https://vagas.isac.org.br/. Lá os interessados podem acessar informações relativas ao perfil das vagas, cargos disponíveis, bem como todos os critérios que serão utilizados no processo para seleção dos profissionais, desde a inscrição até a contratação."},
  {id: 3, question: "Quem pode se candidatar às vagas?", answer: "Para participar dos processos seletivos os candidatos devem preencher alguns requisitos: - Ter nacionalidade brasileira ou ser naturalizado, - Ter título de eleitor,- Ter certificado de reservista ou prova de alistamento no serviço militar (para os candidatos do sexo masculino com até 45 anos de idade);- Ter idade mínima de 18 (dezoito) anos completos na data da contratação;- Ter escolaridade mínima exigida para o cargo pretendido;- Apresentar os documentos que se fizerem necessários por ocasião da inscrição e contratação;- Cumprir as determinações do edital."},
  {id: 4, question: "Como são realizados os processos seletivos para atuação nas unidades do ISAC?", answer: "Nossos processos seletivos ocorrem em três etapas. A primeira fase é a inscrição, que deve obedecer, obrigatoriamente, o prazo definido no edital. O candidato que efetuar a inscrição após o prazo estabelecido está automaticamente eliminado. A segunda etapa é a análise curricular, com caráter eliminatório e classificatório. Por fim, os candidatos selecionados passam por uma entrevista por competências, que também tem caráter eliminatório e classificatório."},
  {id: 5, question: "Como fazer a inscrição para participar da seleção?", answer: "O ISAC publica aqui, no Portal de Vagas, todas às informações relativas às vagas. Elas são publicadas em edital, que podem ser consultados aqui. As vagas abertas possuem um link para inscrição. Os interessados devem clicar e serão redirecionados ao site Seleção Genesis (www.selecaogenesis.com.br). É lá que o candidato preenche sua inscrição e realiza, quando necessário, as provas on-line."},
  {id: 6, question: "Como fazer a inscrição para participar da seleção?", answer: "O ISAC publica aqui, no Portal de Vagas, todas às informações relativas às vagas. Elas são publicadas em edital, que podem ser consultados aqui"},
]


const HomePageTemplate: React.FC<HomePageTemplateProps> = ({
  states,
  posts,
  banners,
  loading,
  onChange,
  onKeyUp,
  defaultIMG,
  dataOpened,
  dataClosed,
  openedDefaultValue,
  closedDefaultValue,
  textHome,
  titleOpenedOpportunies,
  titleClosedOpportunies,
  duvidasFrequentes
}) => {
  return (
    <React.Fragment>
      {banners && <CarouselComponent defaultIMG={defaultIMG} data={banners} />}
      <Container className="mt-5">
        <Row className="py-3">
          <Col>
            {/* <Form.Control size="lg" type="text" placeholder="Buscar Vagas..." /> */}
            {/* <Search
              placeholder="Busque a vaga"
              value="teste"
              onKeyUp={onKeyUp}
            /> */}
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row className="text-center py-3">
            <Col md={6} className="mb-5">
            <Link to="/processos-seletivos-andamento">
            <Botao>Processos seletivos em andamento</Botao>
          </Link>
           
            </Col>
            <Col md={6}>
            <Link to="/processos-seletivos-encerrados">
              <Botao>Processos seletivos finalizados</Botao>
          </Link>
           
            </Col>
        </Row>
        {/* <Row className="py-">
          <Col md={6}>
            <select
              id={titleOpenedOpportunies}
              value={closedDefaultValue}
              onChange={onChange}
            >
              <option value={0}>Estado</option>
              {states &&
                states?.map((item: any) => (
                  <option key={item.id} value={item?.sigla}>
                    {item?.estado}
                  </option>
                ))}
            </select>
          </Col>
          <Col md={6}>
            <select
              id={titleClosedOpportunies}
              value={openedDefaultValue}
              onChange={onChange}
            >
              <option value={0}>Status</option>
              <option value={"Andamento"}>Em andamento</option>
              <option value={"Finalizado"}>Finalizado</option>
            </select>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Col md={12} className="text-center">
              <Spinner animation="grow" />
            </Col>
          ) : (
            <React.Fragment>
              {dataClosed?.length === 0 ? (
                <Col md={12} className="text-center mt-5">
                  <Alert variant="light">Selecione o Estado e o status do processo seletivo desejado</Alert>
                </Col>
              ) : (
                dataClosed?.slice(0, 4).map((item) => (
                  <Col md={6} key={item?.id}>
                    <CardComponent
                      image={item?.image}
                      title={item.titulo}
                      button={"Ver Edital"}
                      excerpt={removeHtmlTags(
                        `${removeHtmlTags(item?.descricao?.substr(0, 250))}...`
                      )}
                      link={`/processo/${item?.slug}`}
                    />
                  </Col>
                ))
              )}
            </React.Fragment>
          )}
        </Row> */}
      </Container>
      <Container>
        <Row>
        <Col md={8}>
            <TitleComponent title={"Vagas em destaque"} />
          </Col>
        </Row>
        <Row>
            {posts?.map((item)=> (
          <Col md={6} key={item?.id}>
              <CardComponent
              title={item?.titulo}
              button={"Ver Vaga"}
              excerpt={removeHtmlTags(
                `${removeHtmlTags(item?.descricao?.substr(0, 250))}...`
              )}
              link={`/processo/${item?.slug}`}
              />
          </Col>
            ))}
        </Row>
        <Row className="mt-5">
        <Col md={8}>
          <TitleComponent title={"Dúvidas Frequentes"} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {duvidasFrequentes?.map((item)=> (
            <FaqComponent 
              id={item.id}
              question={item.question}
              answer={item.Answer}
            />
            ))}
          </Col>
        </Row>
        <Row className="mt-5">
        <Col md={8}>
            <TitleComponent title={"O ISAC"} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <p>Somos uma organização social sem fins lucrativos, com sede em Brasília, no Distrito Federal. Nossa missão é promover a gestão otimizada na área da saúde com resultados positivos para usuários, parceiros e organizações públicas e privadas.</p>
          <p>Acreditamos que, para oferecer uma assistência segura e de qualidade, precisamos colocar as pessoas em primeiro lugar: usuários, colaboradores, prestadores de serviço, todos os que fazem parte do dia a dia nas nossas unidades.</p>
          <p>O nosso Jeito ISAC de ser valoriza e respeita as pessoas. Por isso adotamos a meritocracia como um dos nossos pilares e, assim, contribuímos para o desenvolvimento de profissionais cada vez mais qualificados na área.</p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default HomePageTemplate;
