import React from "react";
import { useParams } from "react-router-dom";
import SinglePage from "../../components/elements/SinglePage";
import ProcessService from "../../services/ProcessService";
import { Files } from "../../types/process";
import { Edital, State } from "../../types/single";

type SingleProcessProps = {
  titulo?: string;
  descricao?: string;
  arquivos?: Files[];
  Edital?: Edital;
  etapa?: State;
};

const SingleProcess = () => {
  const [data, setData] = React.useState<SingleProcessProps>();
  const [loading, setLoading] = React.useState(true);
  const params = useParams<{ slug: string }>();

  React.useEffect(() => {
    const { slug } = params;
    ProcessService.getSingleProcess(slug)
      .then((response) => {
        setData(response.data[0]);
        setLoading(false);
      })
      .catch((error) => error.message);
  }, [params]);

  return (
    <SinglePage
      etapa={data?.etapa}
      titulo={`${data?.titulo}`}
      edital={data?.Edital}
      params={params.slug}
      loading={loading}
      arquivos={data?.arquivos}
      descricao={`${data?.descricao}`}
    />
  );
};

export default SingleProcess;
