import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { CardProps } from '../../../types/card';
import { CardContent, Btn, Divisor } from './styles';

const CardComponent: React.FC<CardProps> = ({
  image,
  title,
  excerpt,
  link,
  button
}) => {
  return (
    <CardContent>
      <Card className="p-3 shadow-lg border-0">
        {image && (
          <Link to={`${link}`} title={title}>
            <Card.Img variant="top" src={image} alt={title} />
          </Link>
        )}
        <Card.Body className="px-1">
          <Card.Title>
            <Link to={`${link}`} title={title}>
              {title}
            </Link>
          </Card.Title>
          {!image && <Divisor />}
          <Card.Text>
            <Link to={`${link}`} title={title}>
              {excerpt}
            </Link>
          </Card.Text>
          <Btn>
            <Link to={`${link}`} title={title}>
              {button}
            </Link>
          </Btn>
        </Card.Body>
      </Card>
    </CardContent>
  );
};

export default CardComponent;
